//@ts-expect-error
import featureToggles from '<product-features>';

export const productFeatureToggles = featureToggles as FeaturesToggles;

export interface FeaturesToggles {
  SIGN_IN_RATE_LIMIT: boolean;

  /**
   * If the real estate listing is published by an agent and the agency is one
   * of RockAgent or Unicredit, the agent's phone has priority over the agency's phone.
   */
  CONTACT_FORM_AGENT_PHONE_PRIORITY_OVER_AGENCY: boolean;

  /**
   * Enable the search for auctions.
   */
  AUCTIONS_ENABLED: boolean;

  /**
   * Enable the filter for virtual tours in the real estate list.
   */
  VIRTUAL_TOUR_FILTER: boolean;

  /**
   * Enable the filter for commercial activities type in the real estate list.
   */
  COMMERCIAL_ACTIVITY_TYPE_FILTER: boolean;

  /**
   * Enable the social buttons.
   */
  SOCIALS_ENABLED: boolean;

  /**
   * Enable the real estate evaluation button on the homepage.
   */
  EVALUATE_BUILDING: boolean;

  /**
   * Change the type of parameter managed for the "rooms" filter. Some products
   * have "rooms", others have "bedrooms".
   */
  BEDROOM_SEARCH_ENABLED: boolean;

  /**
   * Enable the search for the subtypes of "Rooms".
   */
  ONLY_COMPLETE_BEDROOM_SEARCH_ENABLED: boolean;

  /**
   * Enable the new types of commercial premises as categories.
   */
  COMMERCIAL_SEARCH_AS_CATEGORIES: boolean;

  /**
   * Enable the laboratory search among the new commercial types.
   */
  LABORATORY_SEARCH_ENABLED: boolean;

  /**
   * Add the meta <meta name="apple-itunes-app">
   */
  MOBILE_UPSELLING: boolean;

  /**
   * Enable the display of the points of interest in the real estate detail.
   */
  POI_ENABLED: boolean;

  /**
   * Change the path of the first image of a detail, adding cover- to the path.
   */
  LISTING_FIRST_MOSAIC_IMAGE_WATERMARK: boolean;

  /**
   * Enable braze -> www.braze.com.
   */
  BRAZE: boolean;

  /**
   * Enable Braze for desktop anonymous users.
   */
  BRAZE_DESKTOP_ANON_USERS: boolean;

  /**
   * Enable 3d maps in the real estate detail page.
   */
  REAL_ESTATE_3D_MAP_ENABLED: boolean;

  /**
   * Add the "sell house" box in the homepage, add the link to the user menu,
   * add the search in the homepage and enable the agency carousel.
   */
  SELLER_LEAD: boolean;

  /**
   * Adds the "publish listing" button on the homepage, mobile version.
   */
  PUBLISH_MOBILE_BTN: boolean;

  /**
   * Enable the list of dossiers both on the homepage and in the real estate list.
   */
  DOSSIERS_ENABLED: boolean;

  /**
   * Enable the list of associated services both on the homepage and in the real
   * estate list.
   */
  ASSOCIATED_SERVICES: boolean;

  /**
   * Enable the filter of the property type in the search modal.
   */
  SEARCH_MODAL_DISABLE_FILTER_BY_PROPERTY_TYPE: boolean;

  /**
   * Enable the generic placeholder and generic illustrations instead of those
   * with the house.
   */
  GENERIC_IMAGE: boolean;
  PREFERRED_LOCALE_REDIRECT: boolean;
  SEARCH_LIST_SHOW_PROJECT_COMMERCIAL_SURFACE: boolean;

  /**
   * Enable the registration link for agencies in the alternate page version.
   */
  ENABLE_ALTERNATE_AGENCY_SIGNUP: boolean;
  VISIT_REQUEST_ENABLED: boolean;
  VISIT_REQUEST_MULTIPLE_CHOICE_DAYS: boolean;
  VISIT_REQUEST_MULTIPLE_CHOICE_TIMETABLES: boolean;
  DOWNLOAD_PROMOTIONAL_BANNER: boolean;
  COUNTRY_SEARCH_SHORTCUT: boolean;
  DISCOVER_TITLE: boolean;

  /**
   * Enable the logo of the main partner in home page.
   */
  MAIN_PARTNER_HOME: boolean;
  AGENCIES_EXPERT_COVER: boolean;
  AGENCIES_EXPERT_ZONE_TITLE_ENABLED: boolean;
  AGENCIES_EXPERT_SCARSITY_ENABLED: boolean;

  /**
   * Enable in listingSearch the search for metro station from autocomplete and map.
   */
  LISTINGS_METRO_SEARCH: boolean;

  /**
   * Enable the search for regions from autocomplete and map.
   */
  REGION_SEARCH_ENABLED: boolean;

  /**
   * Enable the search for nations from autocomplete and map.
   */
  COUNTRY_SEARCH_ENABLED: boolean;

  /**
   * Enable the search for distance from a point from search option in geographySearch component.
   */
  LISTINGS_DISTANCE_SEARCH: boolean;

  INTERNATIONAL_SEARCH_ENABLED: boolean;
  INTERNATIONAL_SEARCH_ASK_TO_AGENCIES_ENABLED: boolean;
  INTERNATIONAL_SEARCH_ONLY_RESIDENTIAL: boolean;
  INTERNATIONAL_SEARCH_DISABLE_AGENCIES: boolean;
  INTERNATIONAL_SEARCH_DISABLE_AUCTIONS: boolean;
  INTERNATIONAL_SEARCH_REMOVE_HEADER_LINK: boolean;
  INTERNATIONAL_HIDE_MORTGAGE: boolean;
  INTERNATIONAL_LABELS_ENABLED: boolean;

  /**
   * Enable the mortgage widget for immobiliare and indomio.
   */
  SHOW_MORTGAGE_WIDGET: boolean;

  /**
   * Enable the mortgage widget for immotop.
   */
  SHOW_IMMOTOP_MORTGAGE_WIDGET: boolean;

  /**
   * Enable the mortgage link in the real estate detail page.
   */
  MORTGAGE_AVAILABLE: boolean;
  LISTING_SHOW_SURFACES_CONSTITUTION: boolean;

  /**
   * Enable the LaLux Assurance widget.
   */
  LALUX_ASSURANCE_ENABLED: boolean;
  SHOW_HEADER_PUBLISH_BTN: boolean;

  /**
   * Show the price chart on the real estate list.
   */
  SHOW_SEARCH_LIST_PRICE_CHART: boolean;

  /**
   * Enable the login modal after a contact/book visit for a not logged in user.
   */
  AUTH_POST_CONTACT: boolean;

  /**
   * Enable the login modal after saving a search for a not logged in user.
   */
  AUTH_POST_SAVE_SEARCH: boolean;

  /**
   * If not authenticated, open a modal with a summary of the ad you want to save.
   * Then open a modal to push you to logging in (if you are a registered user)
   * or send you an email you ask you to registering (if you are not).
   */
  REALESTATE_MODAL_NOT_LOGGED: boolean;

  /**
   * Enable the placeholder for the last thing with the description for the
   * listing publishing.
   */
  PUBLISHADSPLACEHOLDER: boolean;

  /**
   * Enable the 301 redirect for the old projects (/annunci/p-{projectId})
   * to the corresponding listings (annunci/{realEstateId})
   */
  REAL_ESTATE_PROJECTS_REDIRECT: boolean;

  /**
   * Move the placeholder for messaging on the last searches row.
   */
  MESSAGING_IN_LAST_SEARCH: boolean;

  /**
   * Enable the display of the contact card in the last searches and/or real
   * estate listings.
   */
  MESSAGING_IN_LAST_THINGS_ENABLED: boolean;
  REALTIME_NOTIFICATIONS_ENABLED: boolean;

  /**
   * Enable the surroundings section.
   */
  REAL_ESTATE_SURROUNDINGS_SECTION: boolean;

  /**
   * Enable the management of the security token for the login.
   */
  ENABLE_AUTH_CSRF_TOKEN: boolean;
  DETAIL_CLIENT_TRACK_VISIT_ENABLED: boolean;
  CITY_GUIDE_EVALUATION_HREF_OVERRIDE_ENABLED: boolean;

  AGENCIES_HOMEPAGE_SEARCH_LOCALITY_DISABLED: boolean;

  /**
   * Enable an alternative url for mortgages.
   */
  ENABLE_MORTGAGE_ALTERNATIVE_URL: boolean;

  AGENCY_URL_KEYURL: boolean;

  /**
   * Enable the banner "The number 1 in Italy"
   */
  NUMBER_ONE_LINK: boolean;
  TRANSLATE_GA4_PROPS: boolean;
  ENABLE_UNIQUE_AUTH_MODAL: boolean;
  PREVENT_BOT_TRACKING: boolean;
  SHOW_OUTER_ZONE_AGENCY_CAROUSEL: boolean;
  SEGMENT_TRACKING: boolean;
  SEGMENT_BATCHING: boolean;
  FORCE_INTERNATIONAL_PHONE_NUMBER: boolean;

  AGENCY_LIST_NATION_SEARCH: boolean;
  AGENCY_LIST_ADV: boolean;

  /**
   * Show the multi-contact form.
   */
  SHOW_MULTI_CONTACT_FORM: boolean;
  AGENCY_LIST_SEARCH_AGENCIES: boolean;
  ENABLE_FRANCHISING_PAGE: boolean;
  ENABLE_AGENTS_PAGE: boolean;

  /**
   * Enable an aside under the pagination in the real estate list page.
   */
  SEARCH_LIST_BOTTOM_SERVICES: boolean;

  /**
   * Enable expert agencies carousel in home page.
   */
  AGENCIES_EXPERT_HOME: boolean;

  /**
   * Enable expert agencies carousel in real estate list page.
   */
  AGENCIES_EXPERT_SEARCH_LIST: boolean;

  /**
   * Enable order expert local agencies by bundle(gold, bronze)for carousel.
   */
  AGENCIES_EXPERT_SORT_BY_BUNDLE: boolean;

  /*
   * Enable the agency carousel when the search is by distance or area type.
   */
  AGENCIES_EXPERT_BY_DISTANCE_OR_AREA_SEARCH: boolean;

  /**
   * Enable ordering agency expert markers by bundle on the map.
   */
  AGENCIES_EXPERT_MARKERS_SORT_BY_BUNDLE: boolean;

  /**
   * Enable expert agencies markers for province search in real estate list page.
   */
  AGENCIES_EXPERT_SEARCH_LIST_PROVINCE: boolean;

  /**
   * Enable expert agencies markers in real estate list map.
   */
  AGENCIES_EXPERT_SEARCH_LIST_MAP: boolean;
  MACROAREAS_SEARCHES: boolean;
  SEARCH_AGENCIES_COUNTRY: boolean;

  /**
   * Enable the new receive contacts section in the save search modal.
   */
  ACTIVE_SEARCH: boolean;

  SNOWFLAKES: boolean;
  SEARCH_ON_MAP_ISOCHRONOUS: boolean;

  /**
   * Force the display of the agency logo of a specific property on the real
   * estate listings list.
   */
  FORCE_AGENCY_LOGO_ON_SEARCH_LIST: boolean;
  FIX_ALTERNATE_POLYGONS: boolean;
  AGENCY_NAME_SEARCH: boolean;

  /**
   * Show the new properties in the cost and energy section of the real estate
   * detail page.
   */
  REAL_ESTATE_PROPERTIES_IN_COST_AND_ENERGY: boolean;
  REAL_ESTATE_ENERGY_CLASS_COLOR_CUSTOM: boolean;
  LINK_AGENCY_LOGO_ON_SEARCH_LIST: boolean;
  GARDEN_FILTER_ON_FILTER_BAR: boolean;

  /**
   * When a listing has been contacted, show the contacted information on top
   * of each contacted listing card.
   */
  CONTACTED_LISTING_INFO: boolean;

  /**
   * Temporany toggle for new indomio.gr lists: https://indomio.atlassian.net/browse/PROD-2040
   */
  NEW_INDOMIOGR_REDIDENTIAL_LISTS: boolean;

  /**
   * Temporany toggle for new ws-indomita geography api
   */
  GEOGRAPHY_SERVICE_API_GEOGRAPHY_LIST: boolean;
  GEOGRAPHY_SERVICE_API_GEOGRAPHY_INFO: boolean;

  /**
   * Enable the new version of the GeographySearch component. This is the the
   * react version of the original one, written with tiny-lit for
   * indomita-website.
   */
  REACT_GEOGRAPHY_SEARCH: boolean;

  /**
   * Displays an alert to warn that the component in use is an alpha version
   * and therefore may contain errors. It is used in staging during the
   * debugging phase of the component.
   */
  REACT_GEOGRAPHY_SEARCH_ALPHA_ALERT: boolean;

  /**
   * This feature toggle:
   * - sets the default sorting of the listing search to "Relevance" instead of "Automatic";
   * - removes the "Automatic" sorting option from the sorting dropdown;
   * - changes the ordering logic for "Newest listed" from last update date to creation date.
   *
   * https://indomio.atlassian.net/browse/DEV-12982
   */
  LISTING_SEARCH_NEW_SORTING: boolean;

  /**
   * Add some new sorting options to the listings search and change their order.
   * Change the behavior of the "/da-privati" SEO route, to sort the results
   * instead of filtering them.
   *
   * https://indomio.atlassian.net/browse/PROD-2405
   */
  LISTING_SEARCH_NEW_SORTING_OPTIONS: boolean;

  /**
   * Depending on the LISTING_SEARCH_NEW_SORTING_OPTIONS feature, this control
   * enables the “Discounted” option in the list of the new available sorting
   * methods.
   */
  LISTING_SEARCH_DISCOUNTED_SORTING: boolean;

  /**
   * Enable GrowthBook platform for Feature Flagging and A/B testing
   * Please provide also a valid `growthbookConfig` to enable this feature
   */
  GROWTHBOOK: boolean;

  /**
   * Add in the homepage the "my home" placeholder last thing, box discovery
   * and the link to the header and footer.
   */
  MY_HOME: boolean;

  /**
   * Add in the homepage the "my home" search tab
   */
  SEARCH_TAB_MY_HOME: boolean;

  /**
   * Enable adding the cookie partner selection to the segment library.
   */
  SEGMENT_TRACKING_PARTNERS_COOKIE: boolean;

  /**
   * Enable full story browser SDK
   */
  FULLSTORY: boolean;

  /**
   * Enable Listing Sections Viewed event on Listing Detail page
   */
  LISTING_SECTIONS_VIEWED: boolean;

  /**
   * Enable the map MediaTab on the Listing Detail page
   */
  LISTING_DETAIL_MAP_MEDIA_TAB: boolean;

  /**
   * Enable new Partner Banner in Listing Detail page
   */
  LISTING_DETAIL_NEW_PARTNER_BANNER: boolean;

  /**
   * Enable new logo for immotop-lu
   */
  NEW_LOGO: boolean;

  /**
   * To activate a tall logo version
   */
  TALL_LOGO: boolean;

  /**
   * Enable new propose price feature in Listing Detail Page
   */
  PRICE_PROPOSAL: boolean;

  /**
   * Enable availability filter in saved listings page
   */
  SAVED_LISTINGS_AVAILABILITY_FILTER: boolean;

  /**
   * Feature flag momentaneo per personalizzare il payoff
   */
  PAYOFF_CUSTOM_FONT_IMMOTOP: boolean;
}

export const isFeatureEnabled = (key: keyof FeaturesToggles): boolean => {
  return featureToggles[key];
};
